import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CustomerStatsWidgetsResponse } from '../../models/responses/customers-stats-widgets.response';
import { CustomersStatsWidgetsService } from '../../services/customers-stats-widgets.service';

import * as fromActions from './actions';

@Injectable()
export class CustomersStatsWidgetsStoreEffects {
  constructor(
    private dataService: CustomersStatsWidgetsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map(({ data }: IResponseSuccess<CustomerStatsWidgetsResponse>) =>
            fromActions.loadSuccess({
              response: data,
              requestType: request.type,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCountersRequest),
      switchMap(({ property_ids }) =>
        this.dataService.loadCounters(property_ids).pipe(
          map(({ data }: any) =>
            fromActions.loadCountersSuccess({
              response: data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadCountersFailure(error));
          }),
        ),
      ),
    ),
  );
}
