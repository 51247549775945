import { StatsHeaderWidget } from '../../models';
import { CustomerCompanyStatsCounter } from '../../models/requests/customers-stats-widgets.request';

export interface State {
  widgets: StatsHeaderWidget[];
  counters: CustomerCompanyStatsCounter;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  widgets: null,
  counters: null,
  isLoading: false,
  error: null,
};
