import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';

import { floatRound } from '../../helpers/float-round';
import { StatsHeaderWidget } from '../../models';
import { CustomerStatsWidgetsResponseInterval } from '../../models/responses/customers-stats-widgets.response';

import * as fromActions from './actions';
import * as fromState from './state';

const KEYS = ['today', 'last_week', 'current_month', 'current_year'];

@Injectable()
export class CustomersStatsWidgetsStoreReducer {
  createReducer() {
    return createReducer(
      fromState.initialState,
      on(fromActions.loadRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(fromActions.loadSuccess, (state, { response, requestType }) => ({
        ...state,
        widgets: KEYS.map(
          (key): StatsHeaderWidget => {
            const interval: CustomerStatsWidgetsResponseInterval =
              response[key];

            const prefixIcon =
              interval.total.diff_percent >= 0
                ? 'fas fa-arrow-alt-up color--green'
                : 'fas fa-arrow-alt-down color--red';

            const year = new Date().getFullYear() - 1;

            let subtitle: any = {
              key: `year_compare_no_value`,
              params: {
                year,
              },
            };
            if (interval.total.previous > 0) {
              subtitle = {
                key: 'year_compare',
                params: {
                  year,
                  percent_value: floatRound(interval.total.diff_percent),
                },
                prefixIcon,
              };
            }

            return {
              title: {
                key: ['customers_widgets_titles', requestType, key].join('.'),
              },
              value: interval.total.current,
              subtitle,
            };
          },
        ),
        isLoading: false,
        error: null,
      })),
      on(fromActions.loadFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),
      on(fromActions.loadCountersRequest, (state) => ({
        ...state,
        error: null,
      })),
      on(fromActions.loadCountersSuccess, (state, { response }) => ({
        ...state,
        counters: response,
        isLoading: false,
        error: null,
      })),
      on(fromActions.loadCountersFailure, (state, { error }) => ({
        ...state,
        error,
      })),
      on(fromActions.resetState, () => fromState.initialState),
    );
  }
}

export function customersStatsWidgetsReducerFactory(
  reducer: CustomersStatsWidgetsStoreReducer,
) {
  return reducer.createReducer();
}
