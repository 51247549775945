import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';

import { CustomersStatsWidgetsStoreEffects } from './effects';
import {
  customersStatsWidgetsReducerFactory,
  CustomersStatsWidgetsStoreReducer,
} from './reducer';
import { State } from './state';

const REDUCER_TOKEN = new InjectionToken<ActionReducer<Map<string, State>>>(
  'Customers stats widgets state reducer',
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('customersStatsWidgets', REDUCER_TOKEN),
    EffectsModule.forFeature([CustomersStatsWidgetsStoreEffects]),
  ],
  providers: [
    CustomersStatsWidgetsStoreReducer,
    {
      provide: REDUCER_TOKEN,
      deps: [CustomersStatsWidgetsStoreReducer],
      useFactory: customersStatsWidgetsReducerFactory,
    },
  ],
})
export class CustomersStatsWidgetsStoreModule {}
