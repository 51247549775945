import { createAction, props } from '@ngrx/store';

import {
  CustomerCompanyStatsCounter,
  CustomerStatsWidgetsRequest,
} from '../../models/requests/customers-stats-widgets.request';
import { CustomerStatsWidgetsResponse } from '../../models/responses/customers-stats-widgets.response';

export const loadRequest = createAction(
  '[Customers Stats Widgets] Load Request',
  props<{ request: CustomerStatsWidgetsRequest }>(),
);

export const loadSuccess = createAction(
  '[Customers Stats Widgets] Load Success',
  props<{
    response: CustomerStatsWidgetsResponse;
    requestType: 'customer' | 'company';
  }>(),
);

export const loadFailure = createAction(
  '[Customers Stats Widgets] Load Failure',
  props<{ error: any }>(),
);

export const loadCountersRequest = createAction(
  '[Customers Stats Widgets] Load Counter Request',
  props<{ property_ids: number[] }>(),
);

export const loadCountersSuccess = createAction(
  '[Customers Stats Widgets] Load Counter Success',
  props<{
    response: CustomerCompanyStatsCounter;
  }>(),
);

export const loadCountersFailure = createAction(
  '[Customers Stats Widgets] Load Counter Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Customers Stats Widgets] Reset State');
