import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { CustomerStatsWidgetsRequest } from '../models/requests/customers-stats-widgets.request';

@Injectable({
  providedIn: 'root',
})
export class CustomersStatsWidgetsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(payload: CustomerStatsWidgetsRequest) {
    return this.http.get(
      'statistics/customers_totals?' +
        generateSearchQuery(this.dateFormatter.formatObjectDates(payload)),
    );
  }

  loadCounters(property_ids: number[]) {
    return this.http.get(
      'statistics/customers?' +
        generateSearchQuery(
          this.dateFormatter.formatObjectDates({ property_ids }),
        ),
    );
  }
}
